// extracted by mini-css-extract-plugin
export var maineDigest = "maine_digest_2022_02-module--maineDigest--STwPV";
export var heroImage = "maine_digest_2022_02-module--heroImage--nuCwo";
export var columnWrapper = "maine_digest_2022_02-module--columnWrapper--4Gb42";
export var headingLogo = "maine_digest_2022_02-module--headingLogo--gTW-u";
export var headingWrapper = "maine_digest_2022_02-module--headingWrapper--HKOPn";
export var heading = "maine_digest_2022_02-module--heading--TkokQ";
export var instance = "maine_digest_2022_02-module--instance--OPL+t";
export var subheading = "maine_digest_2022_02-module--subheading--4zOKL";
export var textWrapper = "maine_digest_2022_02-module--textWrapper--5xZHx";
export var latestnewsarticleheadline = "maine_digest_2022_02-module--latestnewsarticleheadline--pk9-d";
export var dateline = "maine_digest_2022_02-module--dateline--HYN4B";
export var whatsNew = "maine_digest_2022_02-module--whatsNew--3MPmr";
export var colWrapper = "maine_digest_2022_02-module--colWrapper--57Kr0";
export var leftCol = "maine_digest_2022_02-module--leftCol--c8JO-";
export var rightCol = "maine_digest_2022_02-module--rightCol--P35jV";
export var photoCaption = "maine_digest_2022_02-module--photoCaption--UmHeH";
export var afterCaption = "maine_digest_2022_02-module--afterCaption--eOebr";